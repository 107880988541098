import React from 'react'
import ApartmentPreview from './ApartmentPreview'
import List from './List'
import ListItem from './ListItem'

const ApartmentList = ({ apartments = [], images, itemWidth = [1, 1 / 2, 1 / 3] }) => {
  return (
    <List
      mx={-2}
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      justifyContent={['center', 'initial']}
    >
      {apartments.map((apartment, i) => (
        <ListItem
          p={2}
          key={i}
          width={itemWidth}
          maxWidth={[325, 'initial']}
        >
          <ApartmentPreview
            apartment={apartment}
            fluidImg={images.find((item => item.node.name === apartment.id))}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default ApartmentList
