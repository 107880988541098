import React from 'react'
import { Flex, Box, Heading } from 'rebass'

const SectionHeading = (props) => (
  <Flex mb={5} alignItems="center" flexDirection="column" {...props}>
    <Heading
      as="h2"
      mb={3}
      px={1}
      fontSize={[6, 7]}
      variant="sectionHead"
      textAlign="center"
      color="heading.dark"
      {...props}
    />
    <Box
      sx={{
        width: 60,
        height: 3,
        bg: 'brand.1'
      }}
    />
  </Flex>
)

export default SectionHeading
